import React, {useContext, useEffect, useState} from 'react';
import styled from 'styled-components';
import Footer from '../Footer';
import Navigation from '../Navigation';
import Router, {useRouter} from 'next/router';
import NProgress from 'nprogress';
import {BREAKPOINT} from '../../lib/styles';
import NextStepsNav from '../Navigation/NextStepsNav';
import {zIndex} from 'aac-components/utils/helpers';
import ShortcodePortals from '../Portals/shortcodePortals';
import AppContext from '../AppContext';
import StickyFooter from '../StickyFooter';
import GlobalSliders from '../GlobalSliders';
import GeoTargetTabWrapper from '../GeoTargetTabWrapper';
import {getSegmentNumber} from '../../lib/utils';
import SegmentBanner from '../SegmentBanner';

Router.onRouteChangeStart = (url) => {
    NProgress.start();
};

Router.onRouteChangeComplete = () => {
    if (typeof window !== 'undefined' && window.CallTrk) {
        window.CallTrk.swap();
    }
    NProgress.done();
};

Router.onRouteChangeError = () => NProgress.done();

const Layout = (props) => {
    const {children = '', showNextSteps = true} = props;
    const [pagePadding, setPagePadding] = useState(null);
    const [isClient, setIsClient] = useState(false);
    const [ready, setReadyState] = useState(false);
    const [isAbtastyLoaded, setIsAbtastyLoaded] = useState(false);
    const [hasPagePills, setHasPagePills] = useState(false);

    const {asPath, push} = useRouter();

    const {
        pageProps = {},
        isMobile,
        sessionStorageState: {geolocation = {}} = {},
        callTrackingNumber = {},
    } = useContext(AppContext);
    const {
        data: {
            acf: {
                content_segment = '',
                hide_global_ctas: {
                    next_steps_nav: hideNextSteps,
                    top_banner: hideTopBanner,
                    sticky_footer: hideStickyFooter,
                } = {},
            } = {},
        } = {},
    } = pageProps;
    const contentSegmentNumber = getSegmentNumber(content_segment);

    // on mount
    useEffect(() => {
        if (typeof window !== 'undefined' && window.CallTrk) {
            window.CallTrk.swap();
        }

        const pagePills = document && document?.getElementById('page-pills');
        setHasPagePills(!!pagePills);

        setIsClient(true);
    }, []);

    // padding for page
    useEffect(() => {
        const navContainer = document && document.getElementById('nav-container');
        setPagePadding(navContainer?.clientHeight);
    }, [asPath, isMobile, isClient]);

    // for portals
    useEffect(() => {
        let timeout;
        timeout = setTimeout(() => setReadyState(true), 2000);

        // clean up
        return () => {
            clearTimeout(timeout);
            setReadyState(false);
        };
    }, [asPath]);

    // mutation observer for when abtasty is loaded
    useEffect(() => {
        const observer = new MutationObserver(() => {
            const abtastyScript = document.getElementById('abtasty-script');
            if (abtastyScript) {
                setIsAbtastyLoaded(true);
            } else {
                setIsAbtastyLoaded(false);
            }
        });
        observer.observe(document, {
            childList: true,
            subtree: true,
        });
        return () => observer.disconnect();
    }, []);

    const showNextStepsNav =
        !asPath.includes('/verify-insurance') && !hideNextSteps && showNextSteps;

    return (
        <div>
            <div id="nav-container" className="nav-container">
                <div id="main-nav-container">
                    {!hideTopBanner && (
                        <SegmenBannerStyles>
                            <SegmentBanner />
                        </SegmenBannerStyles>
                    )}
                    <Navigation showNextStepsNav={showNextStepsNav} />
                </div>
                <div className="next-steps-nav__container">
                    {showNextStepsNav && <NextStepsNav />}
                </div>
            </div>
            <div className={`page-content`}>{children}</div>
            {ready && <ShortcodePortals {...pageProps} />}

            {!hideStickyFooter && <StickyFooter />}
            <Footer {...props} />

            <GlobalSliders />

            {!hasPagePills && geolocation?.facility && (
                <GeoTargetTabWrapper
                    content_segment={content_segment}
                    callTrackingNumber={callTrackingNumber}
                    geolocation={geolocation}
                />
            )}

            <style jsx>
                {`
                    .nav-container {
                        position: fixed;
                        width: 100%;
                        background-color: #fff;
                        z-index: ${zIndex('navigation')};
                    }
                    .page-content {
                        padding-top: ${pagePadding || 173}px;
                    }
                    @media screen and (min-width: ${BREAKPOINT}) {
                        .page-content {
                            padding-top: ${pagePadding || 210}px;
                        }
                    }
                    .next-steps-nav__container {
                        min-height: ${showNextStepsNav ? '48px' : '0'};
                    }
                `}
            </style>
        </div>
    );
};

export default Layout;

const SegmenBannerStyles = styled.div`
    #segment-banner {
        border-bottom: 0;
        background: var(--interactive-100);
        font-weight: bold;
    }
    @media screen and (min-width: ${BREAKPOINT}) {
        #segment-banner {
            border-bottom: 1px solid var(--tertiary-400);
            background: var(--tertiary-200);
            font-weight: 400;
        }
    }
    .banner__tooltip {
        display: none;
    }
`;
