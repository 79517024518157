import IconPhone from 'aac-components/components/Icons/Basic/IconPhone';
import CallrailLink from '../CallrailLink';
import {useContext} from 'react';
import AppContext from '../AppContext';
import styled from 'styled-components';

const PhoneShortcode = (props) => {
    const {callTrackingNumber: {display = ''} = {}} = useContext(AppContext);
    return (
        <span className="phone-shortcode">
            <CallrailLinkStyles>
                <CallrailLink>
                    <span className="phone-shortcode__inner">
                        <span className="phone-shortcode__icon">
                            <IconPhone width="16" height="16" fill="#fff" />
                        </span>
                        <span
                            className="phone-shortcode__display"
                            dangerouslySetInnerHTML={{__html: props?.children || display}}
                        />
                    </span>
                </CallrailLink>
            </CallrailLinkStyles>
            <style jsx>
                {`
                    .phone-shortcode {
                        display: inline-block;
                        position: relative;
                        margin-top: 4px;
                    }
                    .phone-shortcode__inner {
                        display: flex;
                        align-items: center;
                        border: 1px solid var(--success);
                        border-radius: 4px;
                        background: #fff;
                        height: 25px;
                    }
                    .phone-shortcode__icon {
                        position: relative;
                        margin-right: 4px;
                        display: flex;
                        align-items: flex-start;
                        max-width: fit-content;
                        background: var(--success);
                        height: 100%;
                        padding: 4px;
                    }
                    .phone-shortcode__display {
                        padding-right: 8px;
                    }
                `}
            </style>
        </span>
    );
};
export default PhoneShortcode;

const CallrailLinkStyles = styled.div`
    .callrail-link > a {
        text-decoration: none;
        font-size: 14px;
        font-weight: bold;
        color: var(--gray-500);
    }
`;
